@import './styles/helpers.css';
@import './styles/rethink.css';
@import '~@netfront/ui-library/dist/index.css';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

*:focus:not(:focus-visible) {
  outline: none;
}

input,
textarea {
  font: inherit;
}

a {
  text-decoration: inherit;
  color: inherit;
}

button {
  font: inherit;
  border: none;
  background: none;
  cursor: pointer;
}

html {
  /* Primary/branding  */
  --navy: #0e2f65;
  --navy-100: #242c58;
  --navy-150: #27305f;
  --navy-200: #26305f;
  --blue: #40519f;
  --blue-200: #4460aa;
  --blue-300: #e7eff9;
  --pale: #b6dff3;
  --pale-200: #f5f8fc;
  --gray-blue: #545466;
  --gray-blue-200: #545466;
  /* Grayscale */
  --black: #061733;
  --gray: #3d3d3d;
  --gray-200: #a9b7c0;
  --gray-300: #e2e2e2;
  --white: #fff;

  /* Problem areas */
  --charcoal: #264653;
  --teal: #33b9a8;
  --yellow: #e9c46a;
  --orange: #f4a261;
  --red-200: #f2856b;
  --purple: #6a537e;
  --purple-200: #dedeef;

  /* State */
  --green: #57bc90;
  --red: #e76f51;
  --complete: --var(--green);
  --error: --var(--red);
  --active: --var(--blue);

  --font-family: 'montserrat';
  --font-primary: 'montserrat';
  --font-secondary: 'hero-new';
  --weight-thin: 100;

  color: var(--gray);
  font-family: montserrat, sans-serif;
  line-height: 1.4;
  font-size: 14px;
}

@media (min-width: 64em) {
  html {
    font-size: 16px;
  }
}

body {
  background-color: var(--pale-200);
  overflow-x: hidden;
}

h1,
h2,
h3,
h4 {
  margin-bottom: 1rem;
}

h1,
.h1 {
  font-family: hero-new, sans-serif;
  font-size: 2.2rem;
  font-weight: 800;
  line-height: 1.2;
}

h2,
.h2 {
  font-family: hero-new, sans-serif;
  font-size: 1.81rem;
  font-weight: 800;
  line-height: 1.2;
}

h3,
.h3 {
  font-family: hero-new, sans-serif;
  font-size: 1.36rem;
  font-weight: 800;
  line-height: 1.2;
}

h4,
.h4 {
  font-family: hero-new, sans-serif;
  font-size: 1.36rem;
  font-weight: 700;
  line-height: 1.82rem;
}

h5,
.h5 {
  font-family: hero-new, sans-serif;
  font-size: 1.23rem;
  font-weight: 700;
  line-height: 1.45rem;
}

h6,
.h6 {
  font-family: hero-new, sans-serif;
  font-size: 1rem;
  line-height: 1.2rem;
}

@media (min-width: 64em) {
  h1,
  .h1 {
    font-size: 2.5rem;
    letter-spacing: 1.2px;
  }
}

.c-tag {
  background-color: var(--blue);
  color: var(--white);
  display: inline-block;
  font-family: var(--font-family);
  padding: 0.4rem 0.6rem;
}

.c-text--underline {
  position: relative;
}

.c-text--underline::after {
  background-color: var(--pale);
  bottom: -1rem;
  content: '';
  height: 4px;
  left: 0;
  position: absolute;
  width: 30%;
}

.ek-app-container h1,
.ek-app-container h2,
.ek-app-container h3,
.ek-app-container h4 {
  color: inherit;
}

.light {
  color: var(--white);
}

.active {
  color: var(--yellow);
}

.subdued {
  color: var(--grey);
  font-style: italic;
  font-weight: 500;
}

.thin {
  font-weight: var(--weight-thin);
}

.uppercase {
  text-transform: uppercase;
}

p {
  font-weight: 300;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.c-snippet ul {
  margin-left: 2rem;
}

.c-snippet li {
  margin-bottom: 0.5rem;
}

ul,
li {
  list-style: none;
}

ol li {
  list-style: decimal;
}

.strong {
  font-weight: 900;
}

.small {
  font-size: 0.8rem;
}

.big {
  font-size: 1.2rem;
}

.c-fadeup {
  animation: fadeUp 1.2s cubic-bezier(0.23, 1, 0.32, 1) forwards 0.5s;
  opacity: 0;
}
.ek-input--14096 div {
  background: #61667f;
  margin-bottom: 0.5rem;
}

.ek-input--14096 div label {
  padding: 1rem 1rem 0 3.5rem;
}

.ek-input--14096 div .ek-input__checkbox {
  left: 0.9rem;
  top: 0.9rem;
}

.ek-snippet-14804 div div div div label p {
  font-weight: 600 !important;
}

.ek-snippet-14804 div div div div label p {
  font-weight: 600 !important;
}

.ek-snippet-14804 div div div div {
  margin-top: 20px !important;
  margin-bottom: 17px !important;
}

.MuiSlider-markLabel {
  color: white !important;
  font-size: 0.95rem;
  top: 40px !important;
  white-space: normal !important;
}

.MuiSlider-markLabelActive {
  color: white !important;
  white-space: normal !important;
}

.MuiSlider-rail {
  background-color: #f4fbff !important;
  border-radius: 8px !important;
  height: 15px !important;
  opacity: 1;
}

.MuiSlider-thumb {
  height: 30px !important;
  width: 30px !important;
}

.MuiSlider-track {
  background-color: #b6dff3 !important;
  height: 15px !important;
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeIn {
  transition: opacity 0.5s;
  opacity: 0;
}

.fadeIn:hover {
  transition: opacity 0.5s;
  opacity: 1;
}

.fadeOut {
  transition: opacity 0.5s;
  opacity: 1;
}

.fadeOut:hover {
  transition: opacity 0.5s;
  opacity: 0;
}

/* .ek-text-snippet p,
.ek-text-snippet a,
.ek-text-snippet ul li {
  color: #fff !important;
} */

.ek-describe.ek-isNotValid {
  display: none;
}

/* .ek-input.ek-isTouched.ek-isNotValid,
.validateForm .ek-input .ek-input.ek-isNotValid {
  border: 2px solid var(--yellow);
  border-radius: 5px;
} */
.ek-snippet-18622,
.ek-snippet-18623,
.ek-snippet-18624,
.ek-snippet-18607 {
  margin-bottom: 1rem;
  padding: 0 !important;
  /* justify-content: space-evenly; */
}

.ek-snippet-16325,
.ek-snippet-16334 {
  padding: 0 !important;
}

.ek-snippet,
.ek-container,
.ek-section-container,
.ek-section,
.ek-snippet.ek-snippet-contentsnippettexttype {
  border: none !important;
  border-radius: 0;
  padding: 0;
}

.ek-Default h1 {
  margin-bottom: 0;
}

/* .ek-snippet h1,
.ek-snippet h2,
.ek-snippet h3 {
  font-family: 'raleway', sans-serif;
} */

.ek-snippet p {
  line-height: 1.8;
}

.ek-question__response {
  width: fit-content !important;
}

.ek-input label {
  margin: 0;
}

.ek-text-snippet ol {
  margin-left: 1rem;
}

.ek-describe {
  padding: 0 !important;
  margin: 0 !important;
  height: unset !important;
}

.ek-quote-body {
  background-color: #fff;
  color: var(--black);
  border-radius: 8px;
  font-style: unset !important;
  padding: 1rem;
}

.ek-quote-container {
  position: relative;
}

.ek-quote-icon {
  background-color: var(--blue);
  border-radius: 50%;
  bottom: 100% !important;
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(50%);
  z-index: 2;
}

.ek-quote-container .ek-quote-icon:last-of-type {
  bottom: 0 !important;
  left: 100%;
  transform: translate(-100%, -50%);
}

/* .ek-question__item,
.ek-question__response {
  color: var(--white);
} */

.slick-slider {
  margin: 0 -2em;
}

.slick-list {
  padding: 1rem 0 !important;
}

.slick-active button {
  background-color: var(--white) !important;
  border-radius: 50%;
}

.slick-dots li button:before {
  color: transparent !important;
  opacity: 1 !important;
  border: 3px solid #fff;
  border-radius: 50%;
  width: 14px !important;
  height: 14px !important;
}

.slick-dots li.slick-active button:before {
  color: var(--white) !important;
  font-size: 1rem;
  opacity: 1 !important;
  border: 1px solid transparent;
}

.slick-arrow {
  display: none !important;
}

.ek-describe.ek-isTouched,
.validateForm .ek-describe {
  display: initial;

  margin-left: auto;
  font-weight: 600;

  /* to ensure the notification takes up no vertical space */
  margin-top: -1rem;
  height: 1rem;
  padding-top: 1rem;
}

.ek-form-item,
.ek-question,
.ek-question__item {
  padding: 0 !important;
  margin: 0 !important;
}
/* 
.ek-form-item {
  margin-bottom: 0.6rem !important;
} */

.ek-question__response {
  padding: 0 !important;
}

.ek-form-top-notification {
  margin-bottom: 0.5rem;
}

@media print {
  .no-print {
    display: none !important;
  }

  * {
    box-shadow: none !important;
    color: black !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: var(--blue) !important;
  }
}

.c-privacy-page,
.c-terms-of-use-page,
.c-terms-of-use-page .ek-section,
.c-privacy-page .ek-section {
  background-color: var(--pale-200) !important;
}

.c-privacy-page div.h-container--small,
.c-terms-of-use-page div.h-container--small {
  max-width: 1600px;
  width: 75%;
  padding-top: 2rem;
}

.c-privacy-page .ek-section-container li {
  margin-bottom: 0.125rem;
  font-weight: 300;
}

@media (min-width: 64em) {
  .c-privacy-page .ek-section,
  .c-terms-of-use-page .ek-section {
    background-image: url(/images/watermark.svg);
    background-repeat: no-repeat;
    background-size: clamp(600px, 50%, 800px) auto;
    background-position-x: 105%;
    background-position-y: -4rem;
  }
}

/* Hack for /module/week-3/abc-model */
.ek-section-4023 .ek-section-container {
  margin: 1rem;
  display: flex;
  flex-direction: column;
}

.ek-section-4023 .ek-section-container > div {
  width: 100%;
  position: relative;
}

.ek-section-4023 .ek-section-container > div:not(:last-child):after {
  position: absolute;
  background-color: var(--blue);
  content: '';
}
.ek-section-4023 .ek-section-container > div:not(:last-child):before {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  content: '';
  border-top: 0.3rem solid var(--blue);
  border-left: 0.3rem solid var(--blue);
}

@media screen and (max-width: 69.999em) {
  .ek-section-4023 .ek-section-container > div:not(:last-child) {
    margin-bottom: 3rem;
  }
  .ek-section-4023 .ek-section-container > div:not(:last-child):after {
    height: 2rem;
    width: 0.3rem;
  }
  .ek-section-4023 .ek-section-container > div:not(:last-child):after,
  .ek-section-4023 .ek-section-container > div:not(:last-child):before {
    bottom: -2rem;
    left: 50%;
  }

  .ek-section-4023 .ek-section-container > div:after {
    transform: translateX(0.75rem);
  }
  .ek-section-4023 .ek-section-container > div:before {
    transform: rotate(225deg);
  }
}

@media screen and (min-width: 70em) {
  .ek-section-4023 .ek-section-container {
    flex-direction: row;
  }
  .ek-section-4023 .ek-section-container > div:not(:last-child):after {
    width: 2rem;
    height: 0.3rem;
  }

  .ek-section-4023 .ek-section-container > div {
    width: calc(100% / 3 - 2rem);
  }
  .ek-section-4023 .ek-section-container > div:not(:last-child) {
    margin-right: 3rem;
  }
  .ek-section-4023 .ek-section-container > div:not(:last-child):after,
  .ek-section-4023 .ek-section-container > div:not(:last-child):before {
    right: -2rem;
    top: 50%;
  }

  .ek-section-4023 .ek-section-container > div:after {
    transform: translateY(0.75rem);
  }
  .ek-section-4023 .ek-section-container > div:before {
    transform: rotate(135deg);
  }
}

.ek-section-4023 .ek-container {
  margin: 1rem;
  height: 100%;
  width: auto;
}

.ek-section-4023 .ek-container .ek-snippet {
  padding: 0;
}

.ek-section-4023 .ek-container > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ek-section-4023 .ek-container > div > div,
.ek-section-4023 .ek-container > div > div:last-child .ek-snippet {
  padding: 1rem;
  margin: 0;
}

.ek-section-4023 .ek-container > div > div:last-child {
  padding-top: 0;
}
.ek-section-4023 .ek-container > div > div:first-child {
  padding-bottom: 0;
}
.ek-section-4023 .ek-container > div > div:first-child .ek-text-snippet > div > div {
  flex-wrap: wrap;
}
/* End of Hack for /module/week-3/abc-model */
.ek-snippet-18622,
.ek-snippet-18623,
.ek-snippet-18624,
.ek-snippet-18607 {
  display: flex;
}

.ek-snippet-9447,
.ek-snippet-9448,
.ek-snippet-9450 {
  background-color: #273158 !important;
  border-radius: 1rem !important;
  padding: 1rem !important;
  display: flex;
  flex-direction: column;
}

.ek-snippet-9447::after,
.ek-snippet-9448::after,
.ek-snippet-9450::after {
  content: 'Done';
  text-transform: uppercase;
  background-color: var(--yellow);
  color: var(--blue);
  border-radius: 0.5rem;
  padding: 0.5rem 0.75rem;
  margin-left: auto;
  cursor: pointer;
  letter-spacing: 1px;
  line-height: 1;
}

.ek-snippet-9447 p,
.ek-snippet-9448 p,
.ek-snippet-9448 label,
.ek-snippet-9450 p,
.ek-snippet-12456 p,
.ek-snippet-9450 .ek-text-snippet p {
  color: var(--white);
}

.ek-snippet-9103 .ek-input label,
.ek-snippet-9721 .ek-input label,
.ek-snippet-9450 .ek-input label {
  /* background-color: var(--lightYellow); */
  margin-bottom: 1rem;
  padding: 1rem 1rem 1rem 3rem;
  border-radius: 5px;
}

.ek-snippet-9103 .ek-input label span,
.ek-snippet-9721 .ek-input label span,
.ek-snippet-9450 .ek-input label span {
  top: 1rem;
  left: 1rem;
  /* border: 1px solid var(--yellow); */
  border-radius: 2px;
  background-color: var(--white) !important;
}

.ek-snippet-9103 .ek-input label span::after,
.ek-snippet-9721 .ek-input label span::after,
.ek-snippet-9450 .ek-input label span::after {
  border-color: #7f7f7f;
}

.ek-question {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
}

.ek-question__item,
.ek-question__response {
  width: 100% !important;
}

textarea.ek-input {
  min-height: 5rem;
}

.ek-form-item {
  background-color: transparent !important;
}

.ek-form-top-notification,
.ek-quesiton-mandatory-indicator {
  display: none;
}

.ek-describe {
  align-self: flex-end;
}

.ek-input {
  padding-top: 0.5rem;
}

.ek-input label {
  padding-bottom: 1rem;
}

input[type='checkbox'] + span {
  background-color: #dedeef;
  border-radius: 4px;
}

input[type='checkbox']:checked + span {
  background-color: var(--blue) !important;
  border-color: var(--blue) !important;
}

input[type='checkbox'] + span::after {
  left: 30% !important;
  transform: rotate(45deg) translate(-50%, -50%) !important;
  top: 50% !important;
}

.ek-snippet .small-button {
  width: 100%;
}

.ek-snippet .medium-button {
  width: 100%;
}

.ek-snippet .large-button {
  font-size: 1.1rem;
  width: 100%;
}

/* Audio snippet style */

.ek__audio--accessible {
  background-color: #373c5b !important;
}

.bounceInDown {
  font-size: 14px;
}

.ek__audio--accessible-title {
  margin: 0 0 1rem 0;
  text-align: center;
}

.ek-section-container {
  align-items: inherit;
}

.ek-avatar-snippet > svg {
  height: 15rem !important;
}

.ek-snippet-14941 {
  width: auto;
}
.ek-snippet-14941 > div {
  width: fit-content;
  padding: 1.2rem;
}
.ek-snippet-14941 > div > div {
  width: fit-content;
}
.ek-snippet-14941 > div > div > h2 {
  width: fit-content;
  margin-bottom: 0px !important;
}

.header-text {
  border-bottom: 2px solid transparent;
  text-decoration: inherit;
  color: var(--blue);
  font-family: europa, sans-serif;
  font-weight: 400;
  padding: 1.25rem;
  transition: 0.15s ease-in-out;
}
.rhap_main-controls-button,
.rhap_time,
.rhap_repeat-button,
.rhap_volume-button {
  color: #fff !important;
}

.rhap_progress-indicator,
.rhap_progress-filled,
.rhap_volume-indicator {
  background: #0c9ab9 !important;
}

.rhap_repeat-button {
  display: none;
}

.ek-audio {
  background-color: #373c5b;
  color: #fff;
  border-radius: 8px;
  margin: 1em 0;
  padding: 1em;
}

.ek-audio__controls {
  margin: 0 !important;
}

.ek-audio__outer {
  background-color: #00c7dd !important;
  height: 0.8em !important;
  margin-top: 0 !important;
  width: 0.8em !important;
}

.ek-audio__file p {
  animation: none !important;
  padding: 0 0 0 1em !important;
}

/* ********************************** */

@media (pointer: coarse), (pointer: none) {
  .ek-h-hide-on-touch-pointer {
    display: none;
  }
}

@media (pointer: fine) {
  .ek-h-hide-on-mouse-pointer {
    display: none;
  }
}
/*
  Orientate kanzi vertically on larger devices
  This can be removed once Kanzi provides this functionality
*/

.kanzi-audio-player {
  flex-direction: column !important;
  top: 5em !important;
  left: calc(100% - 1.75em) !important;
}

.kanzi-audio-controls {
  flex-direction: column !important;
}

.kanzi-audio-player .kanzi-speaker {
  border-radius: 0.15rem 0.15rem 0 0 !important;
  height: 0.8rem !important;
}

.kanzi-audio-volume {
  min-width: 140px !important;
  right: 0px !important;
  transform: translate(5%, -100%) rotate(270deg) !important;
  top: 62% !important;
}

.kanzi-language-menu {
  height: 100% !important;
  right: 0px !important;
  top: 50% !important;
  transform: translate(-35%, -50%) !important;
}

.kanzi-audio-volume .icon-kanzi-volume-mute,
.kanzi-audio-volume .icon-kanzi-volume {
  transform: rotate(90deg);
}

.kanzi-brand {
  bottom: 0 !important;
  text-align: center !important;
  transform: translateY(100%) !important;
}

.ek-section-container li {
  list-style: disc;
  margin-bottom: 1rem;
}

.ek-section-container li::before {
  /* background-color: #000;
  border-radius: 50%;
  content: "" !important;
  left: 0;
  top: .6rem;
  color: rgb(0, 0, 0);
  height: .55rem;
  position: absolute;
  width: .55rem;
  z-index: 999999; */
  display: none;
}

/* .ek-app-container li {
  padding-left: unset;
}  */

/* //ek-app-container */
.ek-app-container li {
  list-style: none;
}

@keyframes fadeLeft {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.a_fade--left {
  opacity: 0;
  animation: fadeLeft 1s;
  animation-delay: 0.7s;
  animation-fill-mode: forwards;
}

.a_fade--up {
  opacity: 0;
  animation: fadeUp 0.6s;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
  animation-timing-function: cubic-bezier(0.13, 0.43, 0.57, 0.88);
}

.a_fade--in {
  opacity: 0;
  animation: fadeIn 1s;
  animation-fill-mode: forwards;
  animation-delay: 0.4s;
}

.ek-section {
  opacity: 0;
  animation: fadeUp 1s;
  animation-delay: 0.5s;
  animation-fill-mode: backwards;
  /* padding: 1rem; */
  opacity: 1;
}

/* Create more spacing on cards (ek-snippet has .9em x padding */
.ek-section-container {
  margin: 0 0 1rem 0 !important;
}

.ek-section-container:last-of-type {
  margin-bottom: 0 !important;
}

/* Add colour to drop downs - on container as content is separate to title */
.ek-event-reveal {
  animation: fadeUp 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  background-color: rgba(75, 194, 202, 0.1);
  border: none !important;
  /* margin: 0 1rem 1rem 1rem !important; */
  opacity: 0;
}

.ek-event-reveal .ek-snippet {
  padding: 1rem !important;
}

.ek-event-reveal-title {
  font-weight: 600;
  justify-content: flex-start !important;
  position: relative;
  text-transform: capitalize;
}

.ek-event-reveal-label {
  flex: 1;
}

.large-button {
  font-size: 1.1rem !important;
  font-weight: 500 !important;
}

.ek-describe {
  display: none !important;
}

#add-response-button {
  color: var(--white);
  margin: 1rem 1rem 1rem 2rem;
  text-transform: uppercase;
}

.ek-theme-breakoutbox-light-blue *,
.ek-theme-breakoutbox *,
.ek-theme-breakoutbox-yellow * {
  color: var(--black);
}

/* .c-scope-form .ek-text-snippet, */
.c-scope-form .ek-question p,
.c-scope-form .ek-input {
  color: black;
}

.c-scope-form .ek-form-item-13327,
.c-scope-form .ek-form-item-13330,
.c-scope-form .ek-form-item-13328,
.c-scope-form .ek-form-item-13329,
.c-scope-form .ek-form-item-13561,
.c-scope-form .ek-form-item-13562,
.c-scope-form .ek-form-item-13262,
.c-scope-form .ek-form-item-13563 {
  display: none !important;
}

.c-scope-form .ek-form-item-13566,
.c-scope-form .ek-form-item-13565,
.c-scope-form .ek-form-item-13568,
.c-scope-form .ek-form-item-13569,
.c-scope-form .ek-form-item-13570,
.c-scope-form .ek-form-item-13571,
.c-scope-form .ek-form-item-13572,
.c-scope-form .ek-form-item-13573,
.c-scope-form .ek-form-item-13574,
.c-scope-form .ek-form-item-13575,
.c-scope-form .ek-form-item-13576,
.c-scope-form .ek-form-item-13578,
.c-scope-form .ek-form-item-13580 {
  /* background-color: var(--pale-200) !important; */
  margin-bottom: 0 !important;
  /* max-width: 48rem !important; */
  margin: 0 auto !important;
  padding: 1rem;
  position: relative;
}

.c-scope-form .ek-form-item-13566:before,
.c-scope-form .ek-form-item-13565:before,
.c-scope-form .ek-form-item-13568:before,
.c-scope-form .ek-form-item-13569:before,
.c-scope-form .ek-form-item-13570:before,
.c-scope-form .ek-form-item-13571:before,
.c-scope-form .ek-form-item-13572:before,
.c-scope-form .ek-form-item-13573:before,
.c-scope-form .ek-form-item-13574:before,
.c-scope-form .ek-form-item-13575:before,
.c-scope-form .ek-form-item-13576:before,
.c-scope-form .ek-form-item-13578:before,
.c-scope-form .ek-form-item-13580:before {
  content: '';
  background-color: var(--pale-200) !important;
}

.c-scope-form .ek-form-item-13571 .ek-question__response .ek-input,
.c-scope-form .ek-form-item-13572 .ek-question__response .ek-input,
.c-scope-form .ek-form-item-13578 .ek-question__response .ek-input,
.c-scope-form .ek-form-item-13574 .ek-question__response .ek-input,
.c-scope-form .ek-form-item-13580 .ek-question__response .ek-input {
  display: flex !important;
  flex-wrap: wrap !important;
  width: 100%;
  /* justify-content: space-between; */
}

.c-scope-form .ek-form-item-13571 .ek-question__response .ek-radio___checkmark,
.c-scope-form .ek-form-item-13578 .ek-question__response .ek-radio___checkmark {
  background-color: transparent !important;
  border-radius: 50% !important;
  border: none !important;
  height: 4rem !important;
  width: 4rem !important;
  position: absolute !important;
  left: 0.165rem;
  transform: translateX(50%);
}

.c-scope-form .ek-form-item-13571 .ek-question__response .ek-radio___checkmark:after,
.c-scope-form .ek-form-item-13578 .ek-question__response .ek-radio___checkmark:after {
  background-color: transparent;
  /* border: 5px solid var(--blue) !important; */
  height: 3.5rem !important;
  width: 3.5rem !important;
}

.c-scope-form .ek-form-item-13571 .ek-question__response .ek-radio__body,
.c-scope-form .ek-form-item-13572 .ek-question__response .ek-radio__body,
.c-scope-form .ek-form-item-13578 .ek-question__response .ek-radio__body,
.c-scope-form .ek-form-item-13574 .ek-question__response .ek-radio__body {
  display: flex;
  flex-direction: column !important;
  align-items: center;
}

.c-scope-form .ek-form-item-13571 .ek-question__response .ek-radio__body img,
.c-scope-form .ek-form-item-13572 .ek-question__response .ek-radio__body img,
.c-scope-form .ek-form-item-13574 .ek-question__response .ek-radio__body img,
.c-scope-form .ek-form-item-13578 .ek-question__response .ek-radio__body img {
  height: 4rem !important;
  width: 4rem !important;
}

.c-scope-form .ek-form-item-13572 .ek-question__response .ek-radio__body img,
.c-scope-form .ek-form-item-13574 .ek-question__response .ek-radio__body img {
  height: 4rem !important;
  width: 4rem !important;
  border: 2px solid #bfbfbf !important;
  border-radius: 50% !important;
  padding: 0.75rem;
}

.c-scope-form .ek-form-item-13580 .ek-question__response .ek-input label {
  padding-left: 0;
  padding-right: 1rem;
}

.ek-input--13580 span {
  background-color: #dedeef;
  border-radius: 40px;
  /* display: flex; */
  /* justify-content: center; */
  text-align: center;
  padding: 0.2rem;
  display: inline-block;
  padding: 0.1rem 0.5rem;
  font-weight: 600;
  color: #2a3154;
  font-size: 0.9rem;
}

.MuiSlider-root {
  color: #394da8 !important;
}

.c-scope-form #add-response-button {
  color: var(--gray);
  font-weight: 600;
}

.c-scope-form label p {
  font-weight: 600;
}

.c-scope-form select {
  border: 2px solid #9d9d9d;
  border-radius: 4px;
  font-family: var(--font-primary);
  padding: 0.85rem;
}

.ek-input--13580 .ek-input__checkbox {
  visibility: hidden;
}

.ek-input--13580 input[type='checkbox'] + span + .ek-checkbox__content {
  background: #dedeef;
  border-radius: 40px;
  color: #1c2448;
  font-weight: bold;
}

.ek-input--13580 input[type='checkbox'] + span + .ek-checkbox__content > div > div > div {
  padding: 7px;
}

.ek-input--13580 input[type='checkbox'] + span + .ek-checkbox__content > div > div > div > button {
  margin-right: 10px !important;
  height: 25px !important;
  width: 25px !important;
}

.ek-input--13580 input[type='checkbox']:checked + span + .ek-checkbox__content {
  background: var(--blue);
  border-radius: 40px;
  color: #fff;
  font-weight: bold;
}

/* Calendar problem area */
.ek-input--13574 label {
  padding-left: 0;
  padding-right: 1rem;
}

.ek-input--13574 input[type='checkbox'] + span + .ek-checkbox__content {
  background-color: #fff;
  border-radius: 4px;
  font-weight: 600;
  padding: 1rem 0 1rem 1rem;
  transition: 0.1s ease-in;
}

.ek-input--13574 input[type='checkbox']:checked + span + .ek-checkbox__content {
  background-color: #f4a261;
}

.ek-input--13574 input[type='checkbox'] + span + .ek-checkbox__content img {
  max-width: 50%;
  margin-bottom: 0.6rem;
}

.ek-input--13574 input[type='checkbox'] + span {
  visibility: hidden;
}

.ek-input--13574 input[type='checkbox']:checked + span {
  visibility: hidden;
}

.ek-input--13574 input[type='checkbox']:checked + span {
  background-color: var(--navy) !important;
  visibility: visible;
}

.ek-input--13574 .ek-input__checkbox {
  left: 75%;
  top: 5%;
}

.ek-input--13574 .ek-input__checkbox:after {
  left: 30% !important;
}

/* Calendar - 'And what were you doing?' inputs */
.c-scope-form .ek-form-item-13572 input[type='radio'] + span img {
  margin-bottom: 0.6rem;
}

.c-scope-form .ek-input--13572 input[type='radio']:checked + .ek-radio__body img {
  background-color: var(--blue);
  border-color: var(--blue) !important;
}

.ek-input--13572 .ek-radio___checkmark {
  visibility: hidden;
}

.ek-input--13572 .ek-radio___label {
  margin-right: 2rem;
  padding-left: 0;
  padding-right: 0;
  width: 20%;
}

@media (min-width: 63em) {
  .ek-input--13572 .ek-radio___label {
    /* margin-right: 0; */
    width: 15%;
  }
}

.ek-form-item-13568,
.ek-form-item-13569,
.ek-form-item-13570,
.ek-form-item-13571,
.ek-form-item-13572,
.ek-form-item-13573,
.ek-form-item-13574 {
  background-color: var(--pale-200) !important;
}

.ek-form-item-14071,
.ek-form-item-13573,
.ek-form-item-13575 {
  padding: 2rem 0 !important;
}

.ek-section-5989 {
  padding-left: 0;
  padding-right: 0;
}

/* Calendar - faces icons */
.c-scope-form .ek-form-item-13571 .ek-question__response .ek-radio__body img,
.c-scope-form .ek-form-item-13578 .ek-question__response .ek-radio__body img {
  border: 5px solid #dedeef;
  border-radius: 50%;
  margin-bottom: 0.4rem;
}

.c-scope-form .ek-form-item-13571 input[type='radio']:checked + .ek-radio__body img,
.c-scope-form .ek-form-item-13578 input[type='radio']:checked + .ek-radio__body img {
  border: 5px solid;
  border-color: var(--blue);
}

.c-scope-form #save-response-button {
  background: var(--blue) url(/SVG/tick-check.svg);
  background-position: center;
  background-size: 65%;
  height: 32px;
  margin-left: 0.6rem;
  width: 32px;
  padding: 0.4rem;
  border-radius: 5px;
  background-repeat: no-repeat;
}

.c-scope-form #discard-response-button,
.c-scope-form #delete-user-generated-response-button {
  background: var(--red) url(/SVG/bin.svg);
  background-position: center;
  background-size: 65%;
  height: 32px;
  margin-left: 0.6rem;
  width: 32px;
  padding: 0.4rem;
  border-radius: 5px;
  background-repeat: no-repeat;
}

.ek-snippet-14009 {
  margin-bottom: 2rem;
}

.h-background--purple-200 .ek-text-snippet p {
  color: #000 !important;
}

.h-background--purple-200 a {
  color: var(--navy) !important;
}

/* Calculator */
.ek-snippet-14183 .ek-question {
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: row;
  background: var(--blue);
  align-items: center !important;
  padding: 1rem 0.8rem !important;
}

.ek-snippet-14183 .ek-form-item-14179 .ek-question {
  background: var(--navy-200);
}

.ek-snippet-14183 .ek-form-item-14181 .ek-question {
  background: var(--blue-200);
}

.ek-snippet-14183 .ek-form-item {
  margin-bottom: 0 !important;
}

.ek-snippet-14183 .ek-question__response {
  position: relative;
  width: auto !important;
}

.ek-snippet-14183 .ek-question__response:after {
  content: '$';
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: -10%;
  transform: translate(-100%, -50%);
}

.ek-snippet-14183 .ek-question__item label {
  border: none !important;
}
.ek-snippet-14183 .ek-question__item p {
  font-weight: 600;
}

.ek-snippet-14183 input {
  border: 2px solid #9d9d9d;
  border-radius: 8px;
}

.ek-section-6409 .ek-snippet {
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0;
  padding: 2rem !important;
  text-align: center;
}

.ek-section-6409 .ek-snippet-14184 {
  background-color: var(--blue);
}
.ek-section-6409 .ek-snippet-14185 {
  background-color: var(--navy-200);
}

.ek-section-6409 .ek-snippet-14186 {
  background-color: var(--navy);
  border-radius: 0 0 8px 8px;
}

.ek-snippet-14186 {
  background-color: var(--blue-200) !important;
}
.ek-section-6409 .ek-snippet-14184 p,
.ek-section-6409 .ek-snippet-14185 p,
.ek-section-6409 .ek-snippet-14186 p {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 0;
}

.ek-section-6409 .ek-snippet-14184 p + p,
.ek-section-6409 .ek-snippet-14185 p + p,
.ek-section-6409 .ek-snippet-14186 p + p {
  font-size: 2rem;
}

/* End calculator  */

/* .ek-section {
  margin-bottom: 1rem;
} */

.ek-event-reveal-title {
  font-size: 1.1rem !important;
  padding-left: 1rem !important;
}

.c-image-map-476 {
  height: 190px !important;
  width: 220px !important;
}

.ek-Tooltip {
  margin-bottom: 3rem;
  position: relative;
}

.ek-Infobox a,
.ek-Tooltip a {
  color: var(--blue) !important;
  font-weight: 600;
}

.ek-section-6446,
.ek-section-6493,
.ek-section-6446 ul li,
.ek-section-6493 ul li {
  color: var(--white) !important;
}

.ek-section-6493 ul,
.ek-section-6446 ul {
  padding: 1rem;
}

/* Module intro images */
.ek-snippet-14503 img,
.ek-snippet-14515 img,
.ek-snippet-14548 img {
  max-width: 200px;
}

/* .ek-text-snippet h2 {
  font-size: 1.36rem;
  line-height: 1.82rem;
} */

.button-snippet {
  background-color: var(--blue) !important;
  border-color: var(--blue) !important;
  color: var(--white) !important;
  text-align: center;
}

.a_document {
  background-color: var(--yellow) !important;
  border-color: var(--yellow) !important;
  border-radius: 8px;
  display: inline-flex !important;
  padding: 0.5rem 2.2rem 0.5rem 1.5rem;
}

.a_document img {
  margin-right: 0.5rem;
}

.a_document p {
  color: var(--black) !important;
  font-size: 1rem;
  font-weight: 700;
  width: auto;
}

.ek-summary__read {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.ek-summary__value {
  align-items: center;
  background-color: var(--blue);
  border: 4px solid var(--navy);
  border-radius: 50%;
  color: var(--white) !important;
  display: inline-flex;
  height: 2.5rem;
  font-weight: 600;
  justify-content: center;
  width: 2.5rem;
}

#save-response-button img,
#discard-response-button img,
#delete-user-generated-response-button img {
  display: none;
}

#save-response-button {
  background-image: url('/SVG/tick-check.svg');
  background-position: center;
  background-size: contain;
  height: 24px;
  margin-left: 0.6rem;
  width: 24px;
}

.ek-snippet-contentsnippetsummarytype > div > div > div > label {
  visibility: hidden;
  height: 0px;
}

.ek-snippet-14352 > div > div > div.ek-question__item,
.ek-snippet-14354 > div > div > div.ek-question__item,
.ek-snippet-14351 > div > div > div.ek-question__item,
.ek-snippet-14442 > div > div > div.ek-question__item {
  visibility: collapse;
  height: 0px;
}

.ek-snippet-14442 > div > div > div.ek-question__item {
  visibility: collapse;
  height: 0px;
}

#discard-response-button,
#delete-user-generated-response-button {
  background-image: url('/SVG/bin.svg');
  background-position: center;
  background-size: contain;
  height: 24px;
  margin-left: 0.6rem;
  width: 24px;
}

/* .ek-image-snippet img {
  border-radius: .5em;
	border: 4px solid rgb(38, 48, 95);
} */

.ek-theme-summary * {
  color: var(--black);
}

/* .ek-event-reveal ul li {
  color: var(--white) !important;
} */

.ek-section-container ol li {
  list-style: decimal !important;
}
.ek-quiz {
  background-color: transparent !important;
  padding: 0 1rem !important;
}

.ek-quiz__button {
  font-weight: 600;
}

.multi-response-text-item-delete-button,
.multi-response-text-item-edit-button,
.multi-response-text-item-save-button,
.multi-response-text-item-cancel-button {
  color: var(--white);
  margin-left: 0.6rem;
}

.multi-response-text-item-save-button,
.multi-response-text-item-edit-button {
  align-items: center;
  background-color: var(--blue);
  border: 2px solid var(--blue);
  border-radius: 8px;
  display: inline-flex;
  font-weight: 600;
  justify-content: center;
  padding: 0.5rem 1.2rem;
  transition: 0.2s ease-in;
}

.multi-response-text-item-delete-button {
  align-items: center;
  background-color: var(--red);
  border: 2px solid var(--red);
  border-radius: 8px;
  display: inline-flex;
  font-weight: 600;
  justify-content: center;
  padding: 0.5rem 1.2rem;
  transition: 0.2s ease-in;
}

.multi-response-text-item-cancel-button {
  align-items: center;
  background-color: var(--white);
  border: 2px solid;
  border-radius: 8px;
  color: var(--blue);
  display: inline-flex;
  font-weight: 600;
  justify-content: center;
  padding: 0.5rem 1.2rem;
  transition: 0.2s ease-in;
}

.multi-response-text-item-text-area {
  margin: 2rem 0 1rem 0 !important;
}

.c-add-new-response-button__container {
  margin-left: auto;
}
.multi-response-text-delete-button > svg {
  color: #fff !important;
  stroke: #fff !important;
}
.multi-response-text-delete-button,
.multi-response-text-add-new-response-button {
  background-color: var(--navy);
  border: 2px solid var(--white);
  color: var(--white);
  font-weight: 600;
  padding: 0.4rem 0.4rem;
}

.multi-response-text-add-new-response-button:disabled {
  background-color: var(--gray-200);
  cursor: not-allowed;
}
.multi-response-text-delete-response-button__icon,
.multi-response-text-add-new-response-button__icon {
  margin-right: 0.6rem;
}
.c-success-message > span {
  color: var(--green) !important;
  align-items: center;
  margin-right: 10px;
}

.diagram-outer {
  margin: 13rem 0 13rem 0 !important;
}

.ek-popup__button {
  background-color: var(--blue) !important;
  color: var(--white);
  font-weight: 600;
  justify-content: flex-start !important;
  padding: 1rem !important;
}

.ek-popup p,
.ek-popup .ek-text-snippet {
  color: #000 !important;
}

.multi-response-text-item-text-area-editing {
  padding: 1.2rem !important;
  color: var(--black);
}

.ek-section-6696 .ek-event-reveal-body {
  max-height: unset;
}

.ek-popup .animated {
  background-color: var(--blue) !important;
  border: 3px solid;
  border-color: #fff;
  padding: 2rem;
}

.ek-popup .animated * {
  color: var(--white) !important;
}

.ek-form-item-15698 {
  margin-top: 2rem !important;
}

.ek-input--15698 {
  display: flex;
}

.ek-input--15698 img {
  max-width: 2rem;
  margin-right: 0.6rem;
}

.ek-radio___label {
  padding: 1rem;
}

.ek-theme-breakoutbox-form-alternate-row .ek-snippet .ek-question__response .ek-input > div:nth-child(2n) {
  background-color: var(--blue-200);
}

.ek-theme-breakoutbox-form-alternate-row .ek-snippet .ek-question__response .ek-input > div:nth-child(2n-1) {
  background-color: var(--blue);
}
.ek-quote-body {
  background: var(--blue-300) !important;
}
.ek-quote-icon {
  visibility: collapse;
}
.slick-active button {
  background-color: var(--blue) !important;
}
.slick-dots li.slick-active button:before {
  color: var(--blue) !important;
}
.slick-dots li button:before {
  border: 3px solid var(--blue) !important;
}
.ek-radio___label .ek-radio___checkmark {
  margin: 0 0.3rem;
}

.ek-input--15698 .ek-radio__body {
  flex-direction: row;
  align-items: center;
  background-color: var(--navy);
  border: 3px solid;
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex;
  font-weight: 600;
  padding: 0.6rem 1rem;
}

.ek-input--15698 .ek-radio___input,
.ek-input--15698 .ek-radio___checkmark {
  display: none;
}

.ek-input--15698 .ek-radio___input:checked + .ek-radio__body {
  background-color: #fe757a;
}

.ek-input--15698 div:first-of-type .ek-radio___input:checked + .ek-radio__body {
  background-color: #03c734;
}

.calendar-explanation-container {
  background-color: var(--navy);
  padding: 2rem 0 1rem 0;
  margin-bottom: 2rem;
  text-align: center;
}

.calendar-explanation-container p {
  max-width: 50rem;
  margin: 0 auto 1rem;
  text-align: justify;
}

.calendar-header-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 50rem;
  margin: auto;
}

.calendar-explanation-container h1 {
  background-color: var(--blue);
  color: var(--white);
  font-family: var(--font-family);
  padding: 0.2rem 0.6rem;
  text-align: center;
}

.slick-track {
  position: absolute !important;
}

/* .ek-snippet .ek-image-snippet img,
.ek-snippet .ek-text-snippet {
  border-radius: 0.5rem;
} */

.MuiSlider-colorPrimary {
  margin-bottom: 1.5rem;
}

.btn-with-icon {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;
}

.btn-with-icon__text {
  margin-left: 20px;
}

.btn-with-icon:hover {
  filter: brightness(95%);
}

.third-module-icon {
  margin-top: -10px;
}
.flex-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: -1.25rem;
}

.flex-container-item--blue-200 {
  background-color: var(--blue-200);
  padding: 10px 15px 5px;
  border-radius: 1rem;
  color: var(--white);
  font-weight: 600;
  width: 9.5rem;
  height: 9rem;
  display: grid;
  align-items: flex-start;
}

.flex-container-item--blue-200__number {
  position: relative;
  top: calc(100% - 4.5rem);
  left: calc(100% - 7.7rem);
  font-size: 1.5rem;
}

.flex-container-item--blue-200__circle {
  border: 3px solid var(--black);
  border-radius: 50%;
  background-color: var(--navy-200);
  background: linear-gradient(90deg, var(--navy-200) 50%, var(--blue-200) 50%);
  width: 2.5rem;
  height: 2.5rem;
}

.flex-container-item--blue-200__text {
  position: relative;
  height: 6.25rem;
  width: 8rem;
  top: -1.5rem;
  left: 0.18rem;
  margin-right: 0.6rem;
  text-transform: uppercase;
  font-size: 0.74rem;
  font-weight: 700;
}

@media screen and (max-width: 500px) {
  .flex-container-item--blue-200 {
    width: 25rem;
    height: 12rem;
  }

  .flex-container-item--blue-200__number {
    top: calc(100% - 4.6rem);
    left: calc(100% - 22.25rem);
  }

  .flex-container-item--blue-200__text {
    font-size: 1.2rem;
    height: 6.25rem;
    width: 22.25rem;
  }
}

.container--navy-100 {
  background-color: var(--navy-100);
}

.container--navy-100__header {
  padding: 0.5rem 1rem;
  background-color: var(--navy-150);
}

.container--navy-100__text {
  padding: 0.5rem 1rem;
}

.grid-calendar-tracker-container {
  display: grid;
  background-color: var(--white);
  color: var(--black);
  grid-template-columns: 6fr 1fr;
  grid-gap: 2rem;
  align-items: center;
  padding: 2rem;
  border-radius: 0.5rem;
}

.grid-calendar-tracker-container div:last-child {
  text-align: center;
}

.grid-calendar-tracker-container-item p {
  font-size: 1.2rem;
  line-height: 2rem;
}

.grid-calendar-tracker-container-item p:last-child {
  margin-top: 0.8rem;
}

.grid-calendar-tracker-container-item a {
  text-decoration: none;
  color: var(--blue);
  font-weight: 700;
}

.grid-calendar-tracker-container-item a:hover {
  color: var(--blue-200);
}

.grid-calendar-tracker-container-item img {
  width: 5rem;
  height: auto;
}

.ek-section-container .ek-Tooltip p span {
  font-weight: 500;
}

/* Table code styles */
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.c-table__head-row {
  background-color: #57bc90;
  color: #0e2f65;
}

.c-table__head:nth-child(even) {
  background-color: #79c9a6;
}

.c-table__head {
  border: 1px solid #a9b7c0;
  padding: 1rem;
  vertical-align: middle;
}

.rec-dot_active {
  box-shadow: 0 0 1px 3px var(--green) !important;
  background-color: rgb(87 188 144) !important;
}
.ek-section-7687 {
  display: none !important;
}
.my-plan-div-content > div > div > .ek-section-7685,
.my-plan-div-content > div > div > .ek-section-8789 {
  display: none !important;
}

.c-table__tbody-row td {
  border: 1px solid #a9b7c0;
  color: #061733;
  padding: 1rem;
}

.c-table__tbody-row:nth-child(odd) {
  background-color: #e7eff9;
}
.c-table__tbody-row:nth-child(even) {
  background-color: #f5f8fc;
}

@media (max-width: 42em) {
  .c-table__tbody-row td {
    display: flex;
    text-align: left !important;
    padding: 0.3em !important;
  }
  .c-table__tbody-row td:before {
    content: attr(data-hr);
    font-weight: 700;
    display: inline-block;
    margin-right: 1.5rem;
    width: 8em;
  }

  .c-table__head-row {
    display: none;
  }
}
