/* Rethink my drink */

.bg-watermark-blue {
  background-image: url(/images/watermark-blue.svg);
  background-repeat: no-repeat;
  background-position: 70%;
}

/* Binge drinking form */
.ek-container-11344 * {
  font-family: inherit;
}

.ek-container-11344 .ek-snippet-contentsnippetformtype {
  padding: 0;
}

.page-icon {
  max-width: 6rem;
}

@media (min-width: 64em) {
  .page-icon {
    max-width: 100%;
  }
}

/* .c-event-trigger--text-and-icon > .c-event-trigger__title {
  margin: 0 auto;
} */

/* global inputs */
.ek-question__response input,
.ek-question__response textarea,
.ek-question__response select {
  border: 2px solid #a9b7c0;
  border-radius: 0;
  padding: 0.8rem;
}

.ek-question__response textarea {
  padding: 1rem;
}

.ek-question__response input:focus,
.ek-question__response textarea:focus,
.ek-question__response select:focus {
  border: 2px solid #57bc90;
}

.ek-question__item label * {
  color: var(--black);
  font-family: 'montserrat';
  font-weight: 600;
}

.ek-radio___label .ek-radio___checkmark {
  background-color: var(--gray-200);
}

.ek-radio___label .ek-radio___checkmark:hover {
  background-color: var(--gray);
}

.ek-radio___label .ek-radio___checkmark::after {
  background-color: var(--white);
  border: 5px solid var(--green);
  height: calc(100% -5px);
  width: calc(100% -5px);
}

.ek-radio___label .ek-radio___checkmark {
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
}

.ek-radio___label {
  padding: 0.6rem 2.5rem !important;
}

/* calendar inputs */
.ek-input--1614 {
  display: flex;
}
.ek-container-9501 > div {
  width: 33%;
}
.ek-container-9501 {
  justify-content: space-between;
}

.ek-snippet-16161,
.ek-container-9501 {
  display: flex;
}

.ek-container-11345,
.ek-container-11346,
.ek-container-11348,
.ek-container-11347 {
  display: flex;
  justify-content: space-evenly;
}

.ek-snippet-16166 {
  display: flex;
  flex-wrap: wrap;
}

.ek-container-9502 {
  display: flex;
  flex-wrap: wrap;
}

.ek-container-9502 .c-snippet,
.ek-snippet-16166 .ek-form-item {
  width: 100%;
}

@media (min-width: 64em) {
  .ek-container-9502 .c-snippet,
  .ek-snippet-16166 .ek-form-item {
    width: 50%;
  }

  .ek-container-9501 .c-snippet,
  .ek-container-9502 .c-snippet:nth-child(odd) {
    padding-right: 0.6rem;
  }
}

.c-scope-form .ek-snippet {
  padding: 0;
}

.ek-form-item-16147,
.ek-form-item-16198,
.ek-form-item-16185,
.ek-snippet-16161,
.ek-container-9501,
.ek-container-9502,
.ek-snippet-16157,
.ek-snippet-16166 {
  background-color: var(--pale-200) !important;
  padding: 2rem !important;
}

.ek-input--16147 {
  display: flex;
}

.ek-input--16147 input[type='radio'] + .ek-radio__body {
  background-color: var(--white);
  border: 2px solid var(--navy);
  font-weight: 700;
  padding: 0.6rem 2rem;
}

/* Did you consume any drinks - rethink */
.ek-snippet .ek-form-item-16147 {
  margin-bottom: 2rem !important;
}
/* End Did you consume any drinks - rethink */

/* Time of day episode */
.ek-input--16185 {
  display: flex;
  flex-wrap: wrap;
}

.ek-input--16185 .ek-radio___checkmark {
  display: none;
}

.ek-input--16185 .ek-radio___label {
  margin-right: 1.25rem;
  padding: 0 !important;
}

.ek-input--16185 input[type='radio'] + .ek-radio__body {
  align-items: center;
  background-color: var(--white);
  border: 4px solid var(--gray-200);
  color: var(--navy);
  font-weight: 700;
  margin-bottom: 0.8rem;
  min-width: 150px;
  padding: 1rem;
  text-transform: uppercase;
  opacity: 0.6;
}

.ek-input--16185 input[type='radio'] + .ek-radio__body img {
  margin-bottom: 0.8rem;
  width: auto !important;
}

.ek-input--16185 input[type='radio']:checked + .ek-radio__body {
  border-color: var(--navy);
  opacity: 1;
}

.ek-form-item-16198 {
  padding-bottom: 0.5rem !important;
}
/* End Time of day episode */

/* Calendar - situations icon radios */
/* .ek-container-9490 img {
  display: none;
} */
#question-snippet-id-16185 + fieldset {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#question-snippet-id-16185 + fieldset .c-radio-button__item:not(:checked) ~ label::before {
  display: none;
}

#question-snippet-id-16185 + fieldset .c-radio-button__item:checked ~ label::before {
  display: none;
}

.multi-response-text-delete > span{
  display: none;
}
#question-snippet-id-16185 + fieldset .c-radio-button .c-label {
  align-items: center;
  background-color: var(--white);
  border: 4px solid var(--gray-200);
  color: var(--navy);
  cursor: pointer;
  font-weight: 700;
  margin-bottom: 0.8rem;
  padding: 1rem;
  pointer-events: none;
  text-transform: uppercase;
  opacity: 0.6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 8rem !important;
  width: 100% !important;
}

#question-snippet-id-16185 + fieldset .c-radio-button__container .c-radio-button input {
  width: 100%;
  height: 100%;
}

#question-snippet-id-16185 + fieldset .c-radio-button {
  width: 50%;
}

.ek-container-9982 > div > div > .c-cms-section > .l-layout > .l-layout__item > div > div > div > .c-input-field > label {
  display: none;
}
#question-snippet-id-16185 + fieldset .c-radio-button__input {
  width: 50% !important;
}

#question-snippet-id-16185 + fieldset .c-radio-button__item:checked + .c-label {
  border: 4px solid var(--navy);
  opacity: 1;
}

#question-snippet-id-16185 + fieldset .c-radio-button-asset {
  margin-bottom: 0.6rem;
}

#question-snippet-id-16185 + fieldset .c-radio-button__icon {
  display: none;
}

#question-snippet-id-16185 + fieldset .c-radio-button__input {
  height: 8rem !important;
}

#question-snippet-id-16185 + fieldset .c-radio-button {
  margin-right: 1rem;
  width: unset;
}
#question-snippet-id-16185 + fieldset .c-radio-button__input {
  height: 8rem !important;
  width: 9rem !important;
}

#question-snippet-id-16185 + fieldset .c-radio-button .c-label {
  width: 9rem !important;
}

/* checkbox */
.ek-input--16147 .ek-radio___checkmark {
  display: none;
}

.ek-input--16147 .ek-radio___label {
  margin-right: 1rem;
  padding: 0 !important;
}

.ek-input--16147 input[type='radio']:checked + .ek-radio__body {
  background-color: var(--navy);
  color: var(--white);
}

.ek-snippet-16157 {
  margin-top: 1rem;
  padding-bottom: 0 !important;
}

.ek-snippet-16157 h2 {
  margin-bottom: 0 !important;
}

/* Relationsip tool */

.ek-snippet-16171 {
  padding: 0 !important;
}

.ek-snippet-16171 .ek-form-item {
  margin-bottom: 1rem !important;
}

/* Calender input component - react-datepicker */
.react-datepicker {
  border: none !important;
  font-family: var(--font-secondary) !important;
}

.react-datepicker__header {
  background-color: var(--navy) !important;
}

.react-datepicker__header * {
  color: var(--white) !important;
}

.react-datepicker__day--selected {
  background-color: var(--navy) !important;
}

/* new shared library */

/* selects */
.c-dropdown-list__control {
  appearance: auto !important;
  cursor: pointer;
  font-family: inherit;
  padding: 0.7rem;
}

.c-dropdown-list__icon {
  display: none;
}

#multi-line-text-snippet-20461,
#multi-line-text-snippet-20462,
#multi-line-text-snippet-20463,
#multi-line-text-snippet-20464,
#multi-line-text-snippet-20465,
#multi-line-text-snippet-20466,
#multi-line-text-snippet-20451,
#multi-line-text-snippet-20452,
#multi-line-text-snippet-20453,
#multi-line-text-snippet-20455,
#multi-line-text-snippet-20456,
#multi-line-text-snippet-20457 {
  min-height: 4rem !important;
}

.c-input-field__input--textarea {
  min-height: 7rem !important;
}

.c-input-field__input,
.c-dropdown-list__control {
  border: 2px solid #a9b7c0 !important;
  border-radius: 0.5rem !important;
}

.c-input-field__input:focus {
  border: 2px solid #57bc90 !important;
}

.c-event-trigger {
  background-color: var(--navy) !important;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem;
  color: #fff;
  border-radius: 3px;
}

.c-event-trigger__icon {
  max-width: 2rem;
  max-height: 2rem;
}

.c-accordion__details .ek-default-container {
  padding: 0 !important;
}

/* Remove fieldset border default */
.c-question-snippet fieldset {
  border: none;
}

/* SVG in checkbox blocks pointer event */
.c-label.c-checkbox__label svg {
  pointer-events: none;
}

.c-question-snippet {
  margin-bottom: 1.5rem;
}

abbr[title] {
  color: var(--red);
  display: none;
  text-decoration: none;
}

.c-question-snippet__question {
  margin-bottom: 1rem;
}

.c-question-snippet__question p {
  color: var(--black);
  font-weight: 600;
}

.c-checkbox {
  margin-bottom: 0.85rem;
}

.c-label.c-checkbox__label svg {
  opacity: 0;
}

.c-radio-button {
  display: flex;
  position: relative;
}

.c-radio-button .c-label {
  display: flex;
  margin-bottom: 1rem;
}

.c-radio-button__icon,
.c-checkbox__icon {
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
}

.c-checkbox__icon-container,
.c-radio-button__icon {
  background-color: var(--gray-200);
  cursor: pointer;
  margin-right: 0.5rem;
  height: 1.5rem !important;
  min-width: 1.5rem !important;
  width: 1.5rem !important;
}

.c-radio-button__icon,
.c-checkbox__icon-container {
  border-radius: 50%;
}

.c-checkbox__input:checked + .c-label .c-checkbox__icon-container,
.c-radio-button__item:checked + .c-label .c-radio-button__icon {
  background-color: var(--green) !important;
  border: 2px solid #fff;
  box-shadow: 0px 0px 0px 2px var(--green);
}

.c-radio-button__item:checked + .c-radio-button__label::before {
  background-color: var(--green) !important;
}

.c-radio-button__icon {
  pointer-events: none;
  outline: none !important;
}

.c-radio-button__input,
.c-checkbox__input {
  position: absolute;
  clip: initial !important;
  clip-path: none !important;
  cursor: pointer;
  height: 1.5rem !important;
  opacity: 0;
  width: 1.5rem !important;
}

.c-radio-button__icon::after {
  background-color: var(--white) !important;
}

.c-event-trigger__title {
  font-weight: 600;
}

.ek-event-reveal {
  background-color: var(--pale-200) !important;
}

.ek-container-11334 .c-image-snippet {
  max-width: 200px;
  margin: 0 auto;
}

.ek-container-11331 svg {
  display: block;
  margin: 0 auto 2rem;
  max-width: 200px;
}

/* override text x padding on text snippets */
.c-text-snippet {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.c-cms-section h1 {
  font-weight: 600 !important;
}

/* Alc-culator */

.react-tabs {
  display: inline-flex;
  margin-bottom: 2rem;
  margin-top: 1rem;
  padding: 1.5rem;
  width: 100%;
}

.ek-group-tabs + .h-spacing {
  padding: 0 2rem 2rem 2rem;
}

.react-tabs__tab {
  align-items: center;
  background-color: #f5f8fc;
  border: 4px solid transparent;
  box-shadow: 0px 3px 6px #00000029;
  color: #8698b4;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: var(--font-secondary);
  font-weight: 600;
  justify-content: center;
  margin-bottom: 0.6rem;
  padding: 1rem 1.5rem;
}

.react-tabs__tab--selected {
  border-color: #0e2f65;
  color: #0e2f65;
}

.react-tabs__tab-list {
  margin-right: 1rem;
}

.react-tabs .c-accordion {
  margin: 0 !important;
  margin-bottom: -0.8rem !important;
  padding: 0;
}

.react-tabs .c-accordion .c-event-trigger {
  background-color: #f5f8fc !important;
  border: none;
  border-radius: 0;
  color: #0e2f65;
  margin-bottom: 0;
  padding: 1rem;
  text-align: left;
  width: 100%;
}

.react-tabs .c-tab-header__image {
  max-height: 2rem;
  width: 1.5rem;
}

.react-tabs .c-accordion .c-event-trigger .c-event-trigger__title {
  font-family: var(--font-secondary);
  font-size: 1.2rem;
  font-weight: 800;
}

.react-tabs .c-accordion__details {
  background-color: #e7eff9;
  padding: 0.6rem 1rem;
}

.react-tabs__tab-panel {
  display: none;
  flex: 1;
}

.react-tabs__tab-panel--selected {
  background-color: #e7eff9;
  box-shadow: 0px 3px 6px #00000029;
  display: block;
}

.react-tabs .c-question-snippet {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.react-tabs .c-question-snippet .c-question-snippet__question {
  margin-bottom: 0;
}

.react-tabs .c-question-snippet .c-question-snippet__question p {
  font-weight: 300;
  white-space: nowrap;
}

.react-tabs .c-input-field__input--number {
  border-color: #0e2f65 !important;
  font-size: 1.2rem;
  font-weight: 600;
  max-width: 100px;
}

.react-tabs .c-success-message {
  display: none;
}

.ek-container-11451 {
  padding: 1rem !important;
}

/* .ek-container-11446 {
  background-color: #F5F8FC;
} */
.ek-container-11481 .c-snippet:first-of-type,
.ek-container-11446 .c-snippet:first-of-type,
.ek-container-11686 .c-snippet:first-of-type {
  font-family: var(--font-secondary);
  color: var(--navy);
  font-weight: 800;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.ek-container-11481 .c-snippet:first-of-type .c-text-snippet,
.ek-container-11446 .c-snippet:first-of-type .c-text-snippet,
.ek-container-11686 .c-snippet:first-of-type .c-text-snippet {
  margin-bottom: 0;
}

/* .ek-container-11444 .h-spacing {
  margin-bottom: .2rem;
} */

.ek-container-11481 .c-snippet:not(:first-of-type),
.ek-container-11446 .c-snippet:not(:first-of-type),
.ek-container-11686 .c-snippet:not(:first-of-type) {
  background-color: #f5f8fc;
  color: var(--navy);
  font-family: var(--font-secondary);
  font-weight: 800;
  margin-bottom: 0.85rem;
  padding: 2rem 0.6rem;
  text-align: center;
}

.ek-container-11481 .c-snippet:not(:first-of-type) *,
.ek-container-11446 .c-snippet:not(:first-of-type) *,
.ek-container-11686 .c-snippet:not(:first-of-type) * {
  font-weight: 800;
  font-size: 1.2rem;
  margin-bottom: 0;
}

@media (min-width: 64em) {
  .ek-group-tabs {
    display: inline-flex;
    margin-bottom: 3rem;
    width: 80%;
  }

  .ek-group-tabs + .h-spacing {
    display: inline-flex;
    position: absolute;
    padding: 0;
  }

  .ek-group-tabs + .h-spacing,
  .ek-container-11446,
  .ek-container-11481,
  .ek-container-11686 {
    max-width: 130px !important;
  }
}

/* Carousel slider styles */

/* This will need review */
/* .netfront-ui-external-brainhubeu .BrainhubCarousel__container {
  max-width: 500px;
  margin: 0 auto;
} */

.netfront-ui-external-brainhubeu .BrainhubCarousel__dots .BrainhubCarousel__dot {
  align-items: center;
  border-radius: 50%;
  display: flex;
  font-size: 0.85rem !important;
  height: 1.5rem;
  justify-content: center;
  margin: 0 0.25rem;
  padding: 0;
  width: 1.5rem;
}

.netfront-ui-external-brainhubeu .BrainhubCarousel__dots .BrainhubCarousel__dot.BrainhubCarousel__dot--selected {
  background-color: var(--navy);
  color: #fff;
  font-weight: 600;
}

.netfront-ui-external-brainhubeu .BrainhubCarousel__dots .BrainhubCarousel__dot:before {
  display: none !important;
}

.netfront-ui-external-brainhubeu .BrainhubCarousel__custom-arrowLeft {
  transform: rotate(270deg);
}

/* feedback range sliders */

.ek-summary-feedback,
.ek-feedback {
  margin: 1rem 0 2rem 0;
}

.ek-summary-feedback div,
.ek-feedback div {
  background-color: var(--navy) !important;
  border-radius: 50px;
  height: 0.85rem !important;
}

.ek-summary-feedback div[role='slider'],
.ek-feedback div[role='slider'] {
  background-color: var(--white) !important;
  border: 3px solid var(--navy);
  height: 1.5rem !important;
  outline: none;
  width: 1.5rem !important;
}

/* Remove bottom margin from snippet content (h1) */
.ek-default-page-title .ek-text-snippet,
.ek-default-page-title .ek-text-snippet h1 {
  margin-bottom: 0;
}

/* Css classes */

.ek-avatar-container {
}

.ek-default-container {
  padding: 1.5rem 2rem 0.5rem 2rem !important;
}

.ek-default-section {
}

.ek-default-page-title {
}

.ek-avatar-section {
}

.ek-avatar-text {
}

.ek-card {
}

.ek-avatar-section .l-layout__item {
  display: flex;
  align-items: center;
  padding: 0 2rem;
}

.c-text-snippet {
  margin-bottom: 2rem;
}

.c-text-snippet a {
  text-decoration: underline;
}

.c-embed-snippet {
  box-shadow: 0px 3px 6px #00000029;
  margin-bottom: 2rem;
}

.c-embed-snippet__title {
  display: none;
}

.ek-summary-feedback-read-only {
  background-color: #e7eff9;
  border-radius: 0.5rem;
  margin-top: 1rem;
  padding: 1rem;
}

.ek-summary-feedback-read-only p {
  color: var(--navy);
  font-family: inherit;
  font-weight: 600;
}

.ek-event-reveal {
  background-color: var(--white) !important;
  padding: 0 2rem;
}

.ek-default-container .ek-event-reveal {
  padding: 0;
}

.c-image-snippet {
  margin-bottom: 2rem;
}

/* .ek-section-8423, */
/* .ek-section-8418 { */

.ek-hide-content {
  display: none !important;
}

.mytools .ek-hide-content-mytools {
  display: none !important;
}

.c-summary-snippet input,
.c-summary-snippet textarea {
  border: 0 !important;
  border-bottom: 1px solid #e7eff9 !important;
  border-left: 12px solid #e7eff9 !important;
  border-radius: 0 !important;
  min-height: unset !important;
  padding: 1rem 1rem 0 1rem !important;
  resize: none;
}

.ek-theme-breakoutbox-light-blue {
  margin-bottom: 2rem;
}

.c-snippet ul li {
  list-style: disc;
}

.c-snippet ul {
  margin-left: 0.5rem;
}

/* Hide multi response messages */
.multi-response-text-how-many-more-responses-can-be-added,
.multi-response-text-minimum-responses-required-help-text {
  display: none;
}

.c-range-slider__mark {
  color: var(--navy);
  font-size: 0.85rem;
  font-weight: 600;
  margin-top: 1.75rem;
}

.ek-container-9613 {
  margin-top: 2rem;
}

.ek-section-8711,
.ek-section-8712,
.ek-section-8713 {
  background-color: var(--pale-200);
  padding: 1rem !important;
}

/* Temp solution to inline labels that break to new line and casue textbox misalignment */

.ek-container-11224 .l-layout--even-split,
.ek-section-8659 .l-layout--even-split {
  align-items: flex-end;
}

.ek-avatar-section .ek-avatar-text {
  position: relative;
  z-index: 1;
}

.ek-avatar-section .ek-avatar-text::before {
  background-color: rgb(231, 239, 249);
  content: '';
  position: absolute;
  height: 3rem;
  left: 50%;
  top: 0%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 3rem;
  z-index: -1;
}

@media (min-width: 1300px) {
  .ek-avatar-section .ek-avatar-text {
    margin-left: -2rem;
  }

  .ek-avatar-section .ek-avatar-text::before {
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

.ek-section-9070 .l-layout,
.ek-section-7238 .l-layout,
.ek-section-7242 .l-layout,
.ek-section-7243 .l-layout {
  align-items: center;
  flex-direction: column !important;
  margin-left: 0 !important;
}

.ek-section-9070 .ek-avatar-text,
.ek-section-7238 .ek-avatar-text,
.ek-section-7242 .ek-avatar-text,
.ek-section-7243 .ek-avatar-text {
  margin-left: 0 !important;
  margin-top: 2rem;
}

.ek-section-9070 .ek-avatar-text::before,
.ek-section-7238 .ek-avatar-text::before,
.ek-section-7242 .ek-avatar-text::before,
.ek-section-7243 .ek-avatar-text::before,
.ek-container-11331 .ek-avatar-text::before {
  left: 50%;
  top: 0%;
}

.ek-container-11331 .ek-avatar-text {
  margin: 1.5rem 0 2rem 0;
  position: relative;
}

.ek-container-11331 .ek-avatar-text::before {
  background-color: rgb(231, 239, 249);
  content: '';
  position: absolute;
  height: 3rem;
  left: 50%;
  top: 0%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 3rem;
  z-index: -1;
}

.ek-container-10109 {
  margin-top: 2rem;
}

.ek-container-10109 .c-question-snippet__question p {
  font-size: 0.85rem;
}

.ek-container-10109 .c-text-snippet {
  margin: 0 0 0.25rem 0;
}

.c-calendar__panel .ek-group-tabs {
  padding-top: 2rem;
  position: relative;
}

.snippet-alc-ulator-title {
  padding: 2rem 0 0 2rem;
}

.ek-quiz-feedback--correct,
.ek-quiz-feedback--incorrect {
  margin-top: 20px;
}

.ek-quiz-feedback--correct > .ek-quiz-feedback__icon > svg {
  color: var(--green);
}

.ek-quiz-feedback__icon {
  display: flex;
}
.c-event-trigger__title {
  margin: 0 auto;
}

.ek-quiz-feedback__message__response {
  padding: 1rem;
  font-weight: bold;
}

.ek-quiz-feedback__message__response {
  display: flex;
}
.ek-quiz-feedback__message__response::before {
  content: 'Your answer: ';
}
.ek-quiz-feedback--incorrect > .ek-quiz-feedback__icon > svg {
  color: red;
}
.ek-quiz-feedback__additional_message {
  padding: 1rem;
  background: var(--blue-300);
  box-shadow: 1px 1px 4px 1px #bcbcbc;
}

.ek-container-11834 > .c-snippet > .ek-text-snippet,
.ek-container-11833 > .c-snippet > .ek-text-snippet,
.ek-container-11832 > .c-snippet > .ek-text-snippet {
  margin-top: 20px;
}
.ek-quiz-feedback-list > li::before {
  content: '• ';
}
.ek-quiz-feedback-list {
  display: block;
}

.ek-quiz-feedback-list > li {
  list-style-type: none;
}

.feedback-link {
  font-weight: bold;
  cursor: pointer;
  color: var(--navy);
}
.ek-container-11330,
.ek-container-11753 > .c-snippet > .ek-text-snippet {
  margin-top: 50px;
}

textarea {
  resize: none;
}
input {
  resize: none;
}

.ek-avatar-text {
  position: relative;
}

.ek-avatar-text .c-snippet__icon {
  align-items: center;
  background-color: #e7eff9;
  border-radius: 50%;
  display: flex;
  height: 3.5rem;
  justify-content: center;
  position: absolute;
  width: 3.5rem;
}

.ek-avatar-text .c-snippet__icon--left-top {
  left: 0;
  top: 0;
  transform: translate(50%, -50%);
}

.ek-avatar-text .c-snippet__icon--right-bottom {
  right: 0;
  top: 100%;
  transform: translate(-50%, -50%) rotate(180deg);
}

.ek-avatar-section {
  margin-bottom: 4rem;
}

.c-embed-snippet {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
}

.ek-avatar-container svg {
  width: 200px;
}

@media (min-width: 64em) {
  .ek-avatar-container svg {
    width: 264px;
  }

  .ek-avatar-section {
    margin-bottom: 0;
  }
}

/* .ek-container-11224 .c-cms-container {
  height: 100%;
}

.c-snippet,
.c-question-snippet {
  height: 100%;
}

.c-question-snippet {
  display: flex;
  flex-direction: column;
}

.c-question-snippet__question {
  margin-bottom: auto;
} */

.js-compare-table td {
  text-align: center;
}

.c-question-snippet .c-buttons__container {
  margin: 0.5rem 0 2rem 0;
}

.h-vertical-flex-direction {
  flex-direction: column;
}

@media (min-width: 64em) {
  .ek-section-8536 .ek-text-snippet,
  .ek-container-11326 .ek-text-snippet,
  .ek-container-11325 .ek-text-snippet {
    margin-bottom: 0;
    min-height: 3rem;
  }

  .ek-container-11224 .c-question-snippet__question,
  .ek-container-11438 .c-question-snippet__question {
    min-height: 3rem;
  }

  .ek-section-8540 .ek-text-snippet {
    min-height: 7rem;
  }
}

.ek-avatar-section {
  padding: 2rem !important;
}

@media (max-width: 64em) {
  .ek-section-7563 .c-question-snippet {
    margin-bottom: 0 !important;
  }

  .ek-section-7563 .l-layout__item {
    margin-bottom: 0.5rem !important;
  }

  .ek-section-7563 .c-cms-section {
    margin-bottom: 3.5rem;
  }
}
