.h-sr-only {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap; /* 1 */
}

.md-w-9-rem {
  width: 9rem;
}

.md-w-10-rem {
  width: 10rem;
}

.md-w-12-rem {
  width: 12rem;
}

.h-color--white {
  color: var(--white);
}

.h-color--red {
  color: var(--red);
}

.h-color--blue {
  color: var(--blue);
}

.h-color--navy {
  color: var(--navy);
}

.h-color--navy-200 {
  color: var(--navy-200);
}

.h-color--black {
  color: var(--black) !important;
}

.h-color--gray-200 {
  color: var(--gray-200);
}

.filter-grayscale-0-7 {
  filter: grayscale(0.7);
}

.filter-grayscale-1 {
  filter: grayscale(1);
}
/* .h-container--small {
    margin: 0 auto;
    max-width: 900px;
    width: 85%;
} */

.h-container {
  max-width: 1600px;
  margin: 0 auto;
  width: 90%;
}

.h-container--small {
  max-width: 1000px;
  margin: 0 auto;
  width: 90%;
}

.margin-0-auto {
  margin: 0 auto;
}

.h-container--large {
  max-width: 2000px;
  margin: 0 auto;
  width: 90%;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.l-flex--important {
  display: flex !important;
}

.l-flex {
  display: flex;
}

.l-align-items-center {
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

.l-flex--column {
  display: flex;
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1;
}

.v-align {
  display: table-cell;
  vertical-align: middle;
  align-items: center;
}

.column {
  flex-direction: column;
}

.table {
  display: table;
}
.cell {
  display: table-cell;
}

.text-align-center {
  text-align: center;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}
.justify-evenly {
  justify-content: space-evenly;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}

.justify-center-important {
  justify-content: center !important;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.m-none {
  margin: 0 !important;
}

.m-b--none {
  margin-bottom: 0;
}

.m-b--xs {
  margin-bottom: 0.2rem;
}

.m-b--small {
  margin-bottom: 0.6rem;
}

.m-b {
  margin-bottom: 1rem;
}

.m-b--med {
  margin-bottom: 1.5rem;
}

.m-b--large {
  margin-bottom: 2rem;
}

.m-b--xl {
  margin-bottom: 3rem;
}

.m-b--xxl {
  margin-bottom: 4rem;
}

.sm-m-b {
  margin-bottom: 1rem;
}

.m-x {
  margin-left: 1rem;
  margin-right: 1rem;
}

.m-x--large {
  margin-left: 2rem;
  margin-right: 2rem;
}

.m-t {
  margin-top: 1rem;
}

.m-t--med {
  margin-top: 1.5rem;
}

.m-t--large {
  margin-top: 2rem;
}

.m-t--xl {
  margin-top: 3rem;
}

.m-t--2xl {
  margin-top: 4rem;
}

.m-t--auto {
  margin-top: auto;
}

.m-y {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.m-l--none {
  margin-left: 0 !important;
}

.m-l--auto {
  margin-left: auto;
}

.m-l {
  margin-left: 1rem;
}

.m-l--xs {
  margin-left: 0.2rem;
}

.right-0 {
  right: 0;
}
.top-0 {
  top: 0;
}

.m-r--xs {
  margin-right: 0.2rem;
}

.m-l--small {
  margin-left: 0.6rem;
}

.m-r {
  margin-right: 1rem;
}

.m-r--x-small {
  margin-right: 0.4rem;
}
.m-r--small {
  margin-right: 0.6rem;
}

.m-r--med {
  margin-right: 1.5rem;
}

.m-r--large {
  margin-right: 2rem;
}

.m-r--xl {
  margin-right: 3rem;
}

.m-r--xxl {
  margin-right: 5rem;
}

.px {
  padding-left: 1rem;
  padding-right: 1rem;
}

.p-y {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.p-y--small {
  padding-bottom: 0.6rem;
  padding-top: 0.6rem;
}

.p-y--large {
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.p-y--xl {
  padding-bottom: 3rem;
  padding-top: 3rem;
}

.p-top--none {
  padding-top: 0 !important;
}

.p-t {
  padding-top: 1rem;
}

.p-t--med {
  padding-top: 1.5rem;
}

.p-t--large {
  padding-top: 2rem !important;
}

.p-t--xl {
  padding-top: 3rem;
}

.p-none {
  padding: 0 !important;
}

.p-none {
  padding: 0 !important;
}

.p {
  padding: 1rem;
}

.p-med {
  padding: 1.5rem;
}

.p-large {
  padding: 2rem;
}

.p-xl {
  padding: 3rem;
}

.p-b--none {
  padding-bottom: 0 !important;
}

.p-b {
  padding-bottom: 1rem;
}

.p-b--large {
  padding-bottom: 2rem;
}

.p-b--xl {
  padding-bottom: 3rem;
}

.p-l {
  padding-left: 1rem;
}

.pt {
  padding: 1.5rem;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.w-50 {
  width: 50%;
}

.w-66 {
  width: 66%;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.h-200 {
  height: 200px;
}

.h-100-px {
  height: 170px;
}
.w-200 {
  width: 200px;
}

.min-h-12 {
  min-height: 3rem;
}

.m-h-90 {
  max-height: 90px;
}

.h-fadein {
  animation: fadeIn 800ms ease 0s;
}

.align-right {
  margin-left: auto;
  margin-right: 0;
}

.h-image-cover {
  object-fit: cover;
  height: 100%;
  position: absolute;
  width: 100%;
}

.h-display--block {
  display: block;
}

.font-med {
  font-size: 1.2rem;
}

.bold {
  font-weight: bold !important;
}
.weight-400 {
  font-weight: 400 !important;
}

.weight-600 {
  font-weight: 600;
}

.weight-700 {
  font-weight: 700;
}

.weight-800 {
  font-weight: 800 !important;
}

.h-font--special {
  font-family: var(--font-special);
}

.h-font--primary {
  font-family: var(--font-family) !important;
}

.h-font--secondary {
  font-family: var(--font-secondary) !important;
}

.h-font--uppercase {
  text-transform: uppercase;
}

.h-font--capitalize {
  text-transform: capitalize;
}

.h-font-italic {
  font-style: italic;
}

.h-rotate {
  transform: rotate(180deg);
}

.h-section {
  padding: 3rem 0;
}

.line-1 {
  line-height: 1 !important;
}

.border-med {
  border: 4px solid;
}

.border--navy {
  border-color: var(--navy) !important;
}

.border--gray-200 {
  border-color: var(--gray-200) !important;
}

.border--gray-300 {
  border-color: var(--gray-300) !important;
}

.border {
  border: 1px solid;
}

.border-med {
  border: 3px solid;
}

.circle--small {
  border-radius: 50%;
  min-width: 2.5rem;
  height: 2.5rem;
}

.grid {
  display: grid;
}

.h-background--black {
  background-color: var(--black);
}

.h-background--navy {
  background-color: var(--navy);
}

.h-background--blue {
  background-color: var(--blue);
}

.h-background--blue-300 {
  background-color: var(--blue-300);
}

.h-background--navy-200 {
  background-color: var(--navy-200);
}

.h-background--purple-200 {
  background-color: var(--purple-200);
}

.h-background--pale-200 {
  background-color: var(--pale-200);
}

.h-background--white {
  background-color: var(--white);
}

.h-background--green {
  background-color: var(--green) !important;
}

.h-underline--blue {
  display: inline-block;
  position: relative;
}

.h-underline--blue:after {
  background-color: var(--blue);
  bottom: -1rem;
  content: '';
  height: 4px;
  left: 0;
  position: absolute;
  width: 40%;
}

.h-events--none {
  pointer-events: none;
}

.desktop-only {
  display: none;
}

.desktop-only-lg {
  display: none;
}

.mobile-only {
  display: block !important;
}

.sm-column {
  flex-direction: column;
}

.w-4 {
  width: 1rem;
}

.w-8 {
  width: 2rem;
}

.w-16 {
  width: 4rem;
}

.w-32 {
  width: 8rem;
}

.m-w-32 {
  min-width: 8rem;
}

.m-w-64 {
  min-width: 16rem;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-xlg {
  max-width: 60rem;
}

.m-w-200 {
  max-width: 200px;
}

.m-w-600 {
  max-width: 600px;
}

.none {
  display: none;
}

.none-important {
  display: none !important;
}

.flex {
  display: flex;
}

@media screen and (min-width: 64em) {
  .sm-column {
    flex-direction: row;
  }

  .sm-m-b {
    margin-bottom: 0;
  }

  .md-m-b--none {
    margin-bottom: 0;
  }

  .mobile-only {
    display: none !important;
  }

  .desktop-only {
    display: block;
  }

  .md-none {
    display: none;
  }

  .md-block {
    display: block;
  }

  .md-flex {
    display: flex;
  }

  .md-flex-1 {
    flex: 1;
  }

  .md-row {
    flex-direction: row;
  }

  .md-align-start {
    align-items: start;
  }

  .md-align-center {
    align-items: center;
  }

  .md-justify-end {
    justify-content: flex-end;
  }

  .md-p-xl {
    padding: 3rem;
  }

  .md-m-l--none {
    margin-left: 0 !important;
  }

  .md-p-l {
    padding-left: 1rem;
  }

  .md-px--xl {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }

  .md-w-auto {
    width: auto;
  }

  .md-w-16 {
    width: 4rem;
  }

  .md-w-32 {
    width: 8rem;
  }

  .md-p-t--xl {
    padding-top: 3rem;
  }

  .md-m-l--auto {
    margin-left: auto;
  }

  .md-w-50 {
    width: 50%;
  }

  .md-m-r {
    margin-right: 1rem;
  }
}

.c-card {
  background-color: var(--white);
  border-radius: 4px;
  display: inline-block;
  padding: 2.5rem 3rem;
  width: 100%;
}

.c-card__inner {
  align-items: flex-start;
  color: var(--black);
  display: flex;
  flex-direction: column-reverse;
}

.c-card__image {
  margin-bottom: 1.5rem;
  margin-left: 0;
}

.c-card__content {
  flex: 1;
}

@media (min-width: 63em) {
  .c-card__inner {
    align-items: center;
    flex-direction: row;
  }

  .c-card__image {
    margin-left: 1.5rem;
  }
}

@media (min-width: 82em) {
  .lg-m-r {
    margin-right: 1rem;
  }
}

.c-background-image {
  background-image: url(/images/bg-leaf.svg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  position: absolute;
  width: 100%;
}

.min-height--150 {
  min-height: 150px;
}

.shadow {
  box-shadow: 0px 3px 6px #00000029;
}

.shadow-lg {
  box-shadow: 0px 3px 32px #00000029;
}

.hover-shadow-lg:hover {
  box-shadow: 0px 3px 32px #00000029;
}

.background--pale-200 {
  background-color: var(--pale-200);
}

.card {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 20.5rem;
  overflow: hidden;
  padding: 2rem;
  transition: 0.15s ease-in-out;
  width: 100%;
}

.card:hover {
  transform: scale(1.02);
}

.pointer {
  cursor: pointer;
}

.radius {
  border-radius: 6px;
}

.radius-lg {
  border-radius: 30px;
}

@media (min-width: 1280px) {
  .hide-lg {
    display: none;
  }

  .desktop-only-lg {
    display: block;
  }
}
.nowrap {
  white-space: nowrap;
}

.filter-grey {
  filter: grayscale(1);
}

.d-inline {
  display: inline;
}

.text-underline {
  text-decoration: underline;
}

.shadow {
  box-shadow: 0px 3px 2rem rgba(143, 157, 176, 0.57);
}

@media (max-width: 42em) {
  .sm-bg-green {
    background-color: var(--green);
  }
}
